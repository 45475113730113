import { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverCloseButton,
  Button,
  ButtonGroup,
} from '@chakra-ui/react';

export default function HomeTourWelcomePopover({
  children,
  isOpen,
  currentlyOpenPopover,
  moveToNextTourStop,
  quitTour,
}) {
  const { initialFocusRef } = useRef();
  return (
    <Popover
      placement="bottom"
      initialFocusRef={initialFocusRef}
      isOpen={isOpen}
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent boxShadow="2xl">
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Welcome!
        </PopoverHeader>
        <PopoverCloseButton onClick={() => quitTour()} />
        <PopoverBody>
          Would you like to take a tour of the website to learn more about
          Stockpulp?
        </PopoverBody>
        <PopoverFooter
          border="0"
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={4}
        >
          <ButtonGroup size="sm">
            <Button
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                moveToNextTourStop(currentlyOpenPopover);
              }}
              colorScheme="blue"
              ref={initialFocusRef}
            >
              Start
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

HomeTourWelcomePopover.propTypes = {
  children: PropTypes.element.isRequired,
  isOpen: PropTypes.bool.isRequired,
  currentlyOpenPopover: PropTypes.number.isRequired,
  moveToNextTourStop: PropTypes.func.isRequired,
  quitTour: PropTypes.func.isRequired,
};
