import PropTypes from 'prop-types';
import Moment from 'moment';
import PulpListComponent from './PulpListComponent';
import PulpListPopover from './PulpListPopover';
import PulpListSkeleton from './PulpListSkeleton';

export default function PulpList({
  loading,
  pulps,
  currentlyOpenPopover,
  moveToPreviousTourStop,
  moveToNextTourStop,
  quitTour,
}) {
  if (loading) return <PulpListSkeleton />;
  return (
    <PulpListPopover
      currentlyOpenPopover={currentlyOpenPopover}
      moveToPreviousTourStop={moveToPreviousTourStop}
      moveToNextTourStop={moveToNextTourStop}
      quitTour={quitTour}
    >
      <PulpListComponent pulps={pulps} />
    </PulpListPopover>
  );
}

PulpList.propTypes = {
  pulps: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      date: PropTypes.instanceOf(Moment).isRequired,
      creator: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  currentlyOpenPopover: PropTypes.number.isRequired,
  moveToPreviousTourStop: PropTypes.func.isRequired,
  moveToNextTourStop: PropTypes.func.isRequired,
  quitTour: PropTypes.func.isRequired,
};
