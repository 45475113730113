import { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Popover,
  PopoverTrigger,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverFooter,
  Button,
  ButtonGroup,
  Box,
} from '@chakra-ui/react';
import { PULP_LIST_TOUR_STOP, tourStops } from '../../Pages/hooks';

export default function PulpListPopover({
  children,
  currentlyOpenPopover,
  moveToPreviousTourStop,
  quitTour,
}) {
  const { initialFocusRef } = useRef();
  return (
    <Popover
      isOpen={tourStops[currentlyOpenPopover] === PULP_LIST_TOUR_STOP}
      closeOnBlur={false}
      arrowSize={15}
      placement="auto"
      initialFocusRef={initialFocusRef}
    >
      <PopoverTrigger>
        <Box w="100%">{children}</Box>
      </PopoverTrigger>
      <PopoverContent boxShadow="2xl">
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Squeeeeeeeeeze 🍊
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton onClick={() => quitTour()} />
        <PopoverBody>
          This is the Pulp list. You can find out what people are saying about
          the selected asset on social media and filter out sources in which you
          are not insterested.
        </PopoverBody>
        <PopoverFooter
          border="0"
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={4}
        >
          <ButtonGroup size="sm">
            <Button
              onClick={() => moveToPreviousTourStop(currentlyOpenPopover)}
              colorScheme="blue"
              ref={initialFocusRef}
            >
              Previous
            </Button>
            <Button
              onClick={() => quitTour()}
              colorScheme="blue"
              ref={initialFocusRef}
            >
              Finish
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

PulpListPopover.propTypes = {
  children: PropTypes.element.isRequired,
  currentlyOpenPopover: PropTypes.number.isRequired,
  moveToPreviousTourStop: PropTypes.func.isRequired,
  quitTour: PropTypes.func.isRequired,
};
