import PropTypes from 'prop-types';
import { MinusIcon } from '@chakra-ui/icons';

export default function PriceDownIcon({ size }) {
  return <MinusIcon h={size} w={size} color="black" />;
}

PriceDownIcon.propTypes = {
  size: PropTypes.string.isRequired,
};
