import PropTypes from 'prop-types';
import { TriangleDownIcon } from '@chakra-ui/icons';

export default function PriceDownIcon({ size }) {
  return <TriangleDownIcon h={size} w={size} color="red.400" />;
}

PriceDownIcon.propTypes = {
  size: PropTypes.string.isRequired,
};
