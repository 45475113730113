import React from 'react';
import PropTypes from 'prop-types';
import { HStack, VStack, Text, Icon } from '@chakra-ui/react';
import { FaReddit, FaTwitter } from 'react-icons/fa';
import Moment from 'moment';

const openInNewTab = (url) => {
  if (!url) return null;
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
  return null;
};

const PulpIcon = ({ icon, h, w }) => (
  <Icon as={icon} color="gray.700" h={h} w={w} />
);

PulpIcon.defaultProps = {
  h: '1rem',
  w: '1rem',
};

PulpIcon.propTypes = {
  icon: PropTypes.func.isRequired,
  h: PropTypes.string,
  w: PropTypes.string,
};

const Heading = ({ creator, date, source }) => (
  <HStack
    data-testid="pulp-heading"
    direction="row"
    justify="space-between"
    w="100%"
    overflowY={['auto', 'auto', 'none', 'none']}
  >
    <HStack spacing="1rem">
      <Text data-testid="pulp-author" fontWeight="600">
        {creator}
      </Text>
      <Text data-testid="pulp-created-date" fontWeight="600">
        {date.startOf('hour').fromNow()}
      </Text>
    </HStack>
    <PulpIcon
      icon={source === 'twitter' ? FaTwitter : FaReddit}
      data-testid="pulp-source"
      h="1.5rem"
      w="1.5rem"
    />
  </HStack>
);

Heading.propTypes = {
  creator: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Moment).isRequired,
  source: PropTypes.string.isRequired,
};

const truncateText = (text) =>
  text.length > 240 ? `${text.substring(0, 236)}...` : text;

export default function Pulp({ pulp }) {
  const { text, date, creator, source, link } = pulp;

  const truncatedText = truncateText(text);
  return (
    <HStack
      data-testid="pulp"
      justify="start"
      w="100%"
      _hover={{
        background: 'gray.50',
        transition: 'background 0.25s',
        transform: 'scale(1.02)',
      }}
      css={{
        ':active': {
          transform: 'scale(0.99)',
          webkitBoxShadow: '0px 0px 2px 1px rgba(0,0,0,0.15)',
          boxShadow: '0px 0px 1px 0.5px rgba(0,0,0,0.0.15)',
        },
      }}
      boxShadow="lg"
      borderRadius="3px"
      onClick={() => openInNewTab(link)}
      p="1.5rem"
      borderWidth="1px"
      as="button"
    >
      <VStack
        borderColor="gray.300"
        data-testid="pulp"
        spacing="0.5rem"
        w="100%"
      >
        <Heading date={date} creator={creator} source={source} />
        <Text
          overflowWrap="break-word"
          fontSize="md"
          data-testid="text-body"
          w="100%"
          align="left"
        >
          {truncatedText}
        </Text>
      </VStack>
    </HStack>
  );
}

Pulp.propTypes = {
  pulp: PropTypes.shape({
    text: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Moment).isRequired,
    creator: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};
