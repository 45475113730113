import PropTypes from 'prop-types';
import AssetDetailComponent from './AssetDetailComponent';
import AssetDetailSkeleton from './AssetDetailSkeleton';

export default function AssetDetail({ change, title, value, isVolume }) {
  if (value)
    return (
      <AssetDetailComponent
        title={title}
        change={change}
        value={value}
        isVolume={isVolume}
      />
    );
  return AssetDetailSkeleton;
}

AssetDetail.defaultProps = {
  isVolume: false,
};

AssetDetail.propTypes = {
  change: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  isVolume: PropTypes.bool,
};
