import PropTypes from 'prop-types';
import { VStack, HStack } from '@chakra-ui/react';
import AssetDetailRow from '../AssetDetailRow/AssetDetailRow';
import ChatMeter from '../ChatMeter';
import SelectedAsset from '../SelectedAsset';

export default function AssetOverviewComponent({
  asset,
  noiseScore,
  currentlyOpenPopover,
  moveToPreviousTourStop,
  moveToNextTourStop,
  quitTour,
}) {
  return (
    <VStack w="100%" align="start">
      <SelectedAsset
        currentlyOpenPopover={currentlyOpenPopover}
        moveToPreviousTourStop={moveToPreviousTourStop}
        moveToNextTourStop={moveToNextTourStop}
        quitTour={quitTour}
        asset={asset}
      />
      <HStack w="20rem" h="20rem">
        <ChatMeter
          noiseScore={noiseScore}
          currentlyOpenPopover={currentlyOpenPopover}
          moveToPreviousTourStop={moveToPreviousTourStop}
          moveToNextTourStop={moveToNextTourStop}
          quitTour={quitTour}
        />
      </HStack>
      <AssetDetailRow asset={asset} />
    </VStack>
  );
}

AssetOverviewComponent.propTypes = {
  asset: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    regularMarketPrice: PropTypes.number.isRequired,
    regularMarketChange: PropTypes.number.isRequired,
    regularMarketChangePercent: PropTypes.number.isRequired,
    marketState: PropTypes.string.isRequired,
    fiftyTwoWeekHigh: PropTypes.number.isRequired,
    fiftyTwoWeekLow: PropTypes.number.isRequired,
    marketCap: PropTypes.number.isRequired,
    averageDailyVolume10Day: PropTypes.number.isRequired,
    regularMarketVolume: PropTypes.number.isRequired,
    fiftyTwoWeekHighChangePercent: PropTypes.number.isRequired,
    fiftyTwoWeekLowChangePercent: PropTypes.number.isRequired,
  }).isRequired,
  noiseScore: PropTypes.number.isRequired,
  currentlyOpenPopover: PropTypes.number.isRequired,
  moveToPreviousTourStop: PropTypes.func.isRequired,
  moveToNextTourStop: PropTypes.func.isRequired,
  quitTour: PropTypes.func.isRequired,
};
