import { HStack, VStack, Skeleton } from '@chakra-ui/react';
import AssetDetailSkeleton from '../AssetDetail/AssetDetailSkeleton';

export default function SelectedAssetHeadingSkeleton() {
  return (
    <HStack data-testid="selected-asset-title" spacing="0.5rem" h="5%" w="100%">
      <VStack spacing="0.5rem" w="100%" h="100%" align="start">
        <HStack spacing="0.25rem" h="3rem" w="100%">
          <Skeleton data-testid="selected-asset-symbol" h="100%" w="15%" />
          <Skeleton data-testid="selected-asset-symbol" h="100%" w="25%" />
          <Skeleton data-testid="selected-asset-symbol" h="100%" w="7.5%" />
          <Skeleton data-testid="selected-asset-symbol" h="100%" w="12.5%" />
        </HStack>
        <Skeleton data-testid="selected-asset-name" h="1.25rem" w="8rem" />
        <HStack w="100%" justify="space-between">
          <AssetDetailSkeleton />
          <AssetDetailSkeleton />
          <AssetDetailSkeleton />
        </HStack>
      </VStack>
    </HStack>
  );
}
