import { HStack, VStack, Skeleton } from '@chakra-ui/react';

export default function PulpSkeleton() {
  return (
    <VStack data-testid="pulp" spacing="0.5rem" h="10rem" w="100%">
      <HStack w="100%" spacing="1rem">
        <VStack w="100%">
          <HStack
            data-testid="pulp-heading"
            direction="row"
            justify="space-between"
            w="100%"
            overflowY={['auto', 'auto', 'none', 'none']}
          >
            <HStack spacing="1rem">
              <Skeleton data-testid="pulp-creator" w="10rem" h="1.5rem" />
              <Skeleton data-testid="pulp-created-date" w="5rem" h="1.5rem" />
            </HStack>
            <Skeleton data-testid="pulp-source" w="1.5rem" h="1.5rem" />
          </HStack>
          <Skeleton data-testid="pulp-content-line" h="1.5rem" w="100%" />
          <Skeleton data-testid="pulp-content-line" h="1.5rem" w="100%" />
          <Skeleton data-testid="pulp-content-line" h="1.5rem" w="100%" />
        </VStack>
      </HStack>
    </VStack>
  );
}
