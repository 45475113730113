import PropTypes from 'prop-types';
import { VStack } from '@chakra-ui/react';
import PageHeader from '../PageHeader';

export default function PageLayout({
  children,
  currentlyOpenPopover,
  moveToNextTourStop,
  quitTour,
  startTour,
}) {
  return (
    <VStack
      id="main-layout-column"
      w={['27em', '30em', '48em', '62em', '80em', '96em', '108em']}
      m="0 auto"
      px={['1rem', '1rem', '2rem', '3rem', '4rem', '5rem']}
      py="2rem"
      spacing="0.5rem"
      justify="center"
      align="center"
      css={{
        '::-webkit-scrollbar': {
          WebkitAppearance: 'None',
          width: 0,
          height: 0,
        },
      }}
    >
      <PageHeader
        currentlyOpenPopover={currentlyOpenPopover}
        moveToNextTourStop={moveToNextTourStop}
        quitTour={quitTour}
        startTour={startTour}
      />
      {children}
    </VStack>
  );
}

PageLayout.propTypes = {
  children: PropTypes.element.isRequired,
  currentlyOpenPopover: PropTypes.number.isRequired,
  moveToNextTourStop: PropTypes.func.isRequired,
  quitTour: PropTypes.func.isRequired,
  startTour: PropTypes.func.isRequired,
};
