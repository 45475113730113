import { Tooltip } from '@chakra-ui/react';
import { MoonIcon } from '@chakra-ui/icons';

export default function PostMarketSessionIcon() {
  return (
    <Tooltip label="Post-market trading hours">
      <MoonIcon h="1rem" w="1rem" />
    </Tooltip>
  );
}
