import PropTypes from 'prop-types';
import PriceUpIcon from './PriceUpIcon';
import PriceDownIcon from './PriceDownIcon';
import PriceFlatIcon from './PriceFlatIcon';

export default function PriceChangeIcon({ currentPriceChange, size }) {
  if (currentPriceChange > 0) return <PriceUpIcon size={size} />;
  if (currentPriceChange < 0) return <PriceDownIcon size={size} />;
  return <PriceFlatIcon />;
}

PriceChangeIcon.propTypes = {
  currentPriceChange: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
};
