import PropTypes from 'prop-types';
import { TriangleUpIcon } from '@chakra-ui/icons';

export default function PriceUpIcon({ size }) {
  return <TriangleUpIcon h={size} w={size} color="green.400" />;
}

PriceUpIcon.propTypes = {
  size: PropTypes.string.isRequired,
};
