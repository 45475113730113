import { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverCloseButton,
  PopoverArrow,
  Button,
  ButtonGroup,
} from '@chakra-ui/react';
import { ASSET_LIST_TOUR_STOP, tourStops } from '../../Pages/hooks';

export default function AssetListPopover({
  children,
  moveToPreviousTourStop,
  moveToNextTourStop,
  quitTour,
  currentlyOpenPopover,
}) {
  const { initialFocusRef } = useRef();

  return (
    <Popover
      isOpen={tourStops[currentlyOpenPopover] === ASSET_LIST_TOUR_STOP}
      onClose={() => quitTour()}
      closeOnBlur={false}
      arrowSize={15}
      placement="auto"
      initialFocusRef={initialFocusRef}
    >
      <PopoverTrigger>
        <Box w="100%">{children}</Box>
      </PopoverTrigger>
      <PopoverContent boxShadow="2xl">
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Stonks! 📈📉
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton onClick={() => quitTour()} />
        <PopoverBody>
          Here you can find a list of assets currently supported by Stockpulp.
          You can select an asset to get market data and social media posts
          related to it.
        </PopoverBody>
        <PopoverFooter
          border="0"
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={4}
        >
          <ButtonGroup size="sm">
            <Button
              onClick={() => moveToPreviousTourStop(currentlyOpenPopover)}
              colorScheme="blue"
            >
              Previous
            </Button>
            <Button
              onClick={() => moveToNextTourStop(currentlyOpenPopover)}
              colorScheme="blue"
              ref={initialFocusRef}
            >
              Next
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

AssetListPopover.propTypes = {
  children: PropTypes.element.isRequired,
  currentlyOpenPopover: PropTypes.number.isRequired,
  moveToPreviousTourStop: PropTypes.func.isRequired,
  moveToNextTourStop: PropTypes.func.isRequired,
  quitTour: PropTypes.func.isRequired,
};
