import { Box, Tooltip, VStack } from '@chakra-ui/react';
import { SunIcon } from '@chakra-ui/icons';

export default function PreMarketTradingSessionicon() {
  return (
    <Tooltip label="Pre-market trading hours">
      <VStack spacing="-0.4rem">
        <SunIcon h="1rem" w="1rem" zIndex={0} translateY="-1.5rem" />
        <Box h="0.5rem" w="1rem" bg="black" zIndex={1} />
      </VStack>
    </Tooltip>
  );
}
