import PropTypes from 'prop-types';
import {
  Text,
  HStack,
  StatArrow,
  StatHelpText,
  StatNumber,
  Stat,
  VStack,
} from '@chakra-ui/react';
import { shortenLargeNumber } from '../../Pages/hooks';

export default function AssetDetailComponent({
  change,
  title,
  value,
  isVolume,
}) {
  return (
    <Stat w="100%" borderWidth="1px" p="5px" borderRadius="3px" boxShadow="lg">
      <VStack align="center">
        <StatHelpText>{title}</StatHelpText>
        <StatNumber>
          {isVolume ? `${shortenLargeNumber(value)}` : `$${value}`}
        </StatNumber>
        <StatHelpText>
          {change !== 0 ? (
            <HStack justify="start">
              <StatArrow type={change > 0 ? 'increase' : 'decrease'} />
              <Text fontWeight="700">{`${(change * 100).toFixed(1)}%`}</Text>
            </HStack>
          ) : null}
        </StatHelpText>
      </VStack>
    </Stat>
  );
}

AssetDetailComponent.defaultProps = {
  isVolume: false,
};

AssetDetailComponent.propTypes = {
  change: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  isVolume: PropTypes.bool,
};
