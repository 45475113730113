import PropTypes from 'prop-types';
import { HStack, VStack, Text } from '@chakra-ui/react';
import TradingSessionIcon from '../TradingSessionIcon/TradingSessionIcon';
import PriceChangeIcon from '../PriceChangeIcon/PriceChangeIcon';

const priceChangeColor = (change) => {
  if (change > 0) return 'green.400';
  if (change < 0) return 'red.400';
  return 'black';
};

export default function AssetHeading({
  asset: {
    displayName,
    symbol,
    regularMarketPrice,
    regularMarketChange,
    marketState,
    regularMarketChangePercent,
  },
  selected,
  iconSize,
  symbolSize,
  nameSize,
}) {
  return (
    <VStack align="start" w="100%" justify="start">
      <HStack>
        <Text fontSize={symbolSize} fontWeight="700">
          {symbol}
        </Text>
        <Text
          fontSize={nameSize}
          fontWeight="700"
          color={priceChangeColor(regularMarketChange)}
        >
          {regularMarketPrice}
        </Text>
        <PriceChangeIcon
          size={iconSize}
          currentPriceChange={regularMarketChange}
        />
        {selected ? (
          <TradingSessionIcon marketState={marketState} />
        ) : (
          <Text
            color={priceChangeColor(regularMarketChange)}
          >{`${regularMarketChangePercent}%`}</Text>
        )}
      </HStack>
      <Text textAlign="start" fontSize="lg" fontWeight="400" noOfLines="1">
        {displayName}
      </Text>
    </VStack>
  );
}

AssetHeading.defaultProps = {
  selected: false,
};

AssetHeading.propTypes = {
  asset: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    regularMarketPrice: PropTypes.number.isRequired,
    regularMarketChange: PropTypes.number.isRequired,
    regularMarketChangePercent: PropTypes.number.isRequired,
    marketState: PropTypes.string.isRequired,
  }).isRequired,
  selected: PropTypes.bool,
  iconSize: PropTypes.string.isRequired,
  symbolSize: PropTypes.string.isRequired,
  nameSize: PropTypes.string.isRequired,
};
