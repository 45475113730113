import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  VStack,
  HStack,
  Text,
  Divider,
  Tooltip,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from '@chakra-ui/react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { HamburgerIcon } from '@chakra-ui/icons';
import HomeTourWelcomePopover from './HomeTourWelcomePopover';
import { tourStops, WELCOME_TOUR_STOP } from '../../Pages/hooks';
import StockpulpLogo from '../StockpulpLogo/StockpulpLogo';
import SimpleModal from '../SimpleModal/SimpleModal';

const loginHandler = (instance) => {
  instance.loginRedirect();
};

const logoutHandler = (instance) => {
  instance.logout();
};

export default function HeaderComponent({
  currentlyOpenPopover,
  moveToNextTourStop,
  quitTour,
  startTour,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { instance, accounts } = useMsal();

  const isAuthenticated = useIsAuthenticated();
  const userEmail = isAuthenticated
    ? accounts[0]?.idTokenClaims.emails[0]
    : 'Unauthorized';

  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <VStack w="100%">
        {' '}
        <HStack w="100%">
          {' '}
          <HStack align="baseline" w="100%">
            {' '}
            <HomeTourWelcomePopover
              isOpen={tourStops[currentlyOpenPopover] === WELCOME_TOUR_STOP}
              currentlyOpenPopover={currentlyOpenPopover}
              moveToNextTourStop={moveToNextTourStop}
              quitTour={quitTour}
            >
              <StockpulpLogo />
            </HomeTourWelcomePopover>
            <HStack
              spacing="1.5rem"
              position={[
                'fixed',
                'inherit',
                'inherit',
                'inherit',
                'inherit',
                'inherit',
              ]}
              bottom={[-1, null, null, null, null, null]}
              left={[-2, null, null, null, null, null]}
              boxShadow={['2xl', 'none', 'none', 'none', 'none', 'none']}
              borderTop={[
                '1px solid rgba(0,0,255,0.1)',
                'none',
                'none',
                'none',
                'none',
                'none',
              ]}
              bgColor={[
                'white',
                'transparent',
                'transparent',
                'transparent',
                'transparent',
                'transparent',
              ]}
              h="3rem"
              w="100%"
              justify={[
                'space-evenly',
                'flex-end',
                'flex-end',
                'flex-end',
                'flex-end',
                'flex-end',
              ]}
              zIndex={12}
            >
              {isAuthenticated ? (
                <Text
                  _hover={{ color: 'blue.400' }}
                  fontSize="sm"
                  fontWeight="600"
                >
                  <Tooltip label="You are signed in!">{`Hello, ${userEmail}!`}</Tooltip>
                </Text>
              ) : null}
              <Menu>
                <MenuButton
                  as={Button}
                  outline="none"
                  backgroundColor="transparent"
                  _hover={{ color: 'blue.400' }}
                  _active={{ backgroundColor: 'transparent' }}
                  fontSize="sm"
                  fontWeight="600"
                  rightIcon={<HamburgerIcon />}
                >
                  Menu
                </MenuButton>
                <MenuList>
                  {isAuthenticated ? (
                    <MenuItem>
                      <Text
                        _hover={{ color: 'blue.400' }}
                        fontSize="sm"
                        fontWeight="600"
                        onClick={() => {
                          navigate('/user-profile');
                        }}
                      >
                        <Tooltip label="View and edit your user settings">
                          Profile
                        </Tooltip>
                      </Text>
                    </MenuItem>
                  ) : null}
                  {pathname === '/user-profile' ? null : (
                    <MenuItem>
                      <Text
                        _hover={{ color: 'blue.400' }}
                        fontSize="sm"
                        fontWeight="600"
                        onClick={() => {
                          startTour();
                        }}
                      >
                        <Tooltip label="Take a tour of the website">
                          Tour Stockpulp
                        </Tooltip>
                      </Text>
                    </MenuItem>
                  )}
                  {isAuthenticated ? (
                    <>
                      <MenuItem onClick={() => onOpen()}>
                        <Text
                          _hover={{ color: 'blue.400' }}
                          fontSize="sm"
                          fontWeight="600"
                        >
                          <Tooltip label="Click here for the latest upates!">
                            Updates
                          </Tooltip>
                        </Text>
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          isAuthenticated
                            ? logoutHandler(instance)
                            : loginHandler(instance)
                        }
                      >
                        <Text
                          _hover={{ color: 'blue.400' }}
                          fontSize="sm"
                          fontWeight="600"
                        >
                          <Tooltip label="Log out.">Log out</Tooltip>
                        </Text>
                      </MenuItem>
                    </>
                  ) : (
                    <MenuItem
                      onClick={() =>
                        isAuthenticated
                          ? logoutHandler(instance)
                          : loginHandler(instance)
                      }
                    >
                      <Text
                        _hover={{ color: 'blue.400' }}
                        fontSize="sm"
                        fontWeight="600"
                      >
                        <Tooltip label="Log in or sign up!">Log in</Tooltip>
                      </Text>
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </HStack>
          </HStack>
        </HStack>
        <Divider />
      </VStack>
      <SimpleModal
        isOpen={isOpen}
        onClose={onClose}
        title={`Welcome, ${userEmail}!`}
        body={`We're so excited you've joined us at Stockpulp.com. Things are really taking off here 🚀 Stay tuned to see what we have in store!`}
        footer="🥳"
        footerTooltip="Party time!"
      />
    </>
  );
}

HeaderComponent.propTypes = {
  currentlyOpenPopover: PropTypes.number.isRequired,
  moveToNextTourStop: PropTypes.func.isRequired,
  quitTour: PropTypes.func.isRequired,
  startTour: PropTypes.func.isRequired,
};
