import { Skeleton, Spacer, Flex } from '@chakra-ui/react';

export default function AssetButtonSkeleton() {
  return (
    <Flex
      data-testid="asset-tab"
      display="column"
      justify="flex-start"
      w="100%"
      p="0.5rem"
    >
      <Skeleton data-testid="asset-tab-symbol" h="1.5rem" w="6rem" />
      <Spacer h="0.25rem" />
      <Skeleton data-testid="asset-tab-name" h="1.25rem" w="5rem" />
    </Flex>
  );
}
