import PropTypes from 'prop-types';
import AssetOverviewComponent from './AssetOverviewComponent';
import AssetOverviewSkeleton from './AssetOverviewSkeleton';

export default function AssetOverview({
  loading,
  asset,
  noiseScore,
  currentlyOpenPopover,
  moveToPreviousTourStop,
  moveToNextTourStop,
  quitTour,
}) {
  if (asset)
    return (
      <AssetOverviewComponent
        asset={asset}
        loading={loading}
        noiseScore={noiseScore}
        currentlyOpenPopover={currentlyOpenPopover}
        moveToPreviousTourStop={moveToPreviousTourStop}
        moveToNextTourStop={moveToNextTourStop}
        quitTour={quitTour}
      />
    );

  return <AssetOverviewSkeleton />;
}

AssetOverview.defaultProps = {
  asset: null,
};

AssetOverview.propTypes = {
  asset: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    regularMarketPrice: PropTypes.number.isRequired,
    regularMarketChange: PropTypes.number.isRequired,
    regularMarketChangePercent: PropTypes.number.isRequired,
    marketState: PropTypes.string.isRequired,
    fiftyTwoWeekHigh: PropTypes.number.isRequired,
    fiftyTwoWeekLow: PropTypes.number.isRequired,
    marketCap: PropTypes.number.isRequired,
    averageDailyVolume10Day: PropTypes.number.isRequired,
    regularMarketVolume: PropTypes.number.isRequired,
    fiftyTwoWeekHighChangePercent: PropTypes.number.isRequired,
    fiftyTwoWeekLowChangePercent: PropTypes.number.isRequired,
  }),
  loading: PropTypes.bool.isRequired,
  noiseScore: PropTypes.number.isRequired,
  currentlyOpenPopover: PropTypes.number.isRequired,
  moveToPreviousTourStop: PropTypes.func.isRequired,
  moveToNextTourStop: PropTypes.func.isRequired,
  quitTour: PropTypes.func.isRequired,
};
