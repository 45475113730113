import { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverFooter,
  Button,
  ButtonGroup,
} from '@chakra-ui/react';

export default function SelectedAssetPopover({
  children,
  isOpen,
  currentlyOpenPopover,
  moveToPreviousTourStop,
  moveToNextTourStop,
  quitTour,
}) {
  const initialFocusRefSelectedAsset = useRef();
  return (
    <Popover
      isOpen={isOpen}
      closeOnBlur={false}
      initialFocusRef={initialFocusRefSelectedAsset}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <Box w="100%">{children}</Box>
      </PopoverTrigger>
      <PopoverContent boxShadow="2xl">
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          The chosen one 🧐
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton onClick={() => quitTour()} />
        <PopoverBody>
          {`Here you can see which asset is currently selected. You can
                quickly get the asset's price and name, as well as the
                current market state. Below you can find additional market data
                and view the trading chart for the previous day.`}
        </PopoverBody>
        <PopoverFooter
          border="0"
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={4}
        >
          <ButtonGroup size="sm">
            <Button
              onClick={() => moveToPreviousTourStop(currentlyOpenPopover)}
              colorScheme="blue"
              ref={initialFocusRefSelectedAsset}
            >
              Previous
            </Button>
            <Button
              onClick={() => moveToNextTourStop(currentlyOpenPopover)}
              colorScheme="blue"
              ref={initialFocusRefSelectedAsset}
            >
              Next
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

SelectedAssetPopover.propTypes = {
  children: PropTypes.element.isRequired,
  isOpen: PropTypes.bool.isRequired,
  currentlyOpenPopover: PropTypes.number.isRequired,
  moveToPreviousTourStop: PropTypes.func.isRequired,
  moveToNextTourStop: PropTypes.func.isRequired,
  quitTour: PropTypes.func.isRequired,
};
