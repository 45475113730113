import PropTypes from 'prop-types';
import GaugeChart from 'react-gauge-chart/dist/GaugeChart';

const chatMeterValue = (value) => {
  if (value <= 5) return 'Very low chatter';
  if (value > 5 && value <= 15) return 'Low chatter';
  if (value > 15 && value <= 40) return 'Average chatter';
  if (value > 40 && value <= 80) return 'High chatter';
  return 'Very high chatter';
};

export default function ChatMeteComponent({ noiseScore }) {
  return (
    <GaugeChart
      percent={noiseScore}
      textColor="#000000"
      formatTextValue={(value) => chatMeterValue(value)}
      colors={['#58E291', '#FFFE2B', '#FF6565']}
      arcsLength={[0.15, 0.35, 0.5]}
    />
  );
}

ChatMeteComponent.propTypes = {
  noiseScore: PropTypes.number.isRequired,
};
