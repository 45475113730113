import { useState, useEffect, useReducer } from 'react';
import { Box, Stack, VStack, Divider } from '@chakra-ui/react';
import PageLayout from '../../Components/PageLayout/PageLayout';
import AssetList from '../../Components/AssetList';
import AssetOverview from '../../Components/AssetOverview';
import PulpList from '../../Components/PulpList';
import PulpFilter from '../../Components/PulpFilter/PulpFilter';
import {
  homePageReducer,
  initialHomePageState,
  SET_SELECTED_ASSET,
  usePulps,
  UPDATE_PULP_SOURCE_TYPES,
  useNoiseIndex,
  nextTourStop,
  previousTourStop,
  quitTourAction,
  startTourAction,
  useAssetList,
} from '../hooks';

export default function Home() {
  const [startTime, updateStartTime] = useState(0);

  useEffect(() => {
    updateStartTime(Date.now());
  }, []);

  const [state, dispatch] = useReducer(homePageReducer, initialHomePageState);

  const { selectedAsset, pulpSourceTypes, tourLocation } = state;

  const { assetList, assetListLoading } = useAssetList();

  useEffect(() => {
    if (!assetListLoading) {
      // eslint-disable-next-line no-console
      console.log(`start time ${startTime}`);
      const diff = (Date.now() - startTime) / 1000;
      // eslint-disable-next-line no-console
      console.log(`time from start up to data loaded in seconds ${diff}`);
    }
  }, [assetListLoading]);

  useEffect(() => {
    if (!assetListLoading && !selectedAsset) {
      dispatch({ type: SET_SELECTED_ASSET, value: assetList[0] });
    }
  }, [assetList]);

  const { pulps, arePulpsLoading } = usePulps(selectedAsset, pulpSourceTypes);

  const { noiseScore } = useNoiseIndex(selectedAsset?.symbol);

  const moveToNextTourStop = (currentLocation) =>
    dispatch(nextTourStop(currentLocation));
  const moveToPreviousTourStop = (currentLocation) =>
    dispatch(previousTourStop(currentLocation));
  const quitTour = () => dispatch(quitTourAction);
  const startTour = () => dispatch(startTourAction);

  return (
    <PageLayout
      currentlyOpenPopover={tourLocation}
      moveToNextTourStop={moveToNextTourStop}
      quitTour={quitTour}
      startTour={startTour}
    >
      <Stack
        direction={['column', 'column', 'row', 'row', 'row', 'row', 'row']}
        flexFlow={[
          'column-reverse',
          'column-reverse',
          'row',
          'row',
          'row',
          'row',
          'row',
        ]}
        id="entire-app-content"
        w="100%"
        align="start"
        justify="space-between"
        spacing={['1rem', '1rem', '3rem', '3rem', '3rem', '3rem', '3rem']}
      >
        <VStack
          id="main-content"
          paddingTop={['5rem', '5rem', 0, 0, 0, 0, 0]}
          w={['100%', '100%', '55%', '65%', '75%', '80%', '80%']}
          spacing="2rem"
          borderColor="gray.300"
          bgColor="white"
        >
          <AssetOverview
            asset={selectedAsset}
            loading={assetListLoading}
            noiseScore={noiseScore}
            currentlyOpenPopover={tourLocation}
            moveToNextTourStop={moveToNextTourStop}
            moveToPreviousTourStop={moveToPreviousTourStop}
            quitTour={quitTour}
          />
          <Divider />
          <PulpFilter
            pulpSourceTypes={pulpSourceTypes}
            setPulpSourceTypes={(value) =>
              dispatch({ type: UPDATE_PULP_SOURCE_TYPES, value })
            }
          />
          <PulpList
            pulps={pulps}
            currentlyOpenPopover={tourLocation}
            moveToNextTourStop={moveToNextTourStop}
            moveToPreviousTourStop={moveToPreviousTourStop}
            quitTour={quitTour}
            loading={arePulpsLoading}
          />
        </VStack>
        <Box
          w={['100%', '100%', '45%', '35%', '25%', '20%', '20%']}
          position={[
            'none',
            'none',
            'sticky',
            'sticky',
            'sticky',
            'sticky',
            'sticky',
          ]}
          top="20px"
          right="0"
        >
          <AssetList
            assets={assetList}
            loading={assetListLoading}
            setSelectedAsset={(value) =>
              dispatch({ type: SET_SELECTED_ASSET, value })
            }
            selectedAsset={selectedAsset}
            quitTour={quitTour}
            moveToPreviousTourStop={moveToPreviousTourStop}
            moveToNextTourStop={moveToNextTourStop}
            currentlyOpenPopover={tourLocation}
          />
        </Box>
      </Stack>
    </PageLayout>
  );
}
