/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { VStack } from '@chakra-ui/react';
import Moment from 'moment';
import Pulp from '../Pulp/Pulp';

export default function PulpListComponent({ pulps }) {
  const pulpList = pulps.map((pulp) => <Pulp pulp={pulp} key={pulp.id} />);
  return (
    <VStack w="100%" id="pulp-list-skeleton" spacing="1.5rem">
      {pulpList}
    </VStack>
  );
}

PulpListComponent.propTypes = {
  pulps: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      date: PropTypes.instanceOf(Moment).isRequired,
      creator: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};
