import PropTypes from 'prop-types';
import ChatMeterComponent from './ChatMeterComponent';
import ChatMeterSkeleton from './ChatMeterSkeleton';
import ChatMeterPopover from './ChatMeterPopover';
import { CHAT_METER_TOUR_STOP, tourStops } from '../../Pages/hooks';

export default function ChatMeter({
  noiseScore,
  currentlyOpenPopover,
  moveToNextTourStop,
  moveToPreviousTourStop,
  quitTour,
}) {
  if (noiseScore >= 0) {
    return (
      <ChatMeterPopover
        isOpen={tourStops[currentlyOpenPopover] === CHAT_METER_TOUR_STOP}
        currentlyOpenPopover={currentlyOpenPopover}
        moveToNextTourStop={moveToNextTourStop}
        moveToPreviousTourStop={moveToPreviousTourStop}
        quitTour={quitTour}
      >
        <ChatMeterComponent noiseScore={noiseScore} />
      </ChatMeterPopover>
    );
  }

  return <ChatMeterSkeleton />;
}

ChatMeter.propTypes = {
  currentlyOpenPopover: PropTypes.number.isRequired,
  moveToPreviousTourStop: PropTypes.func.isRequired,
  moveToNextTourStop: PropTypes.func.isRequired,
  quitTour: PropTypes.func.isRequired,
  noiseScore: PropTypes.number.isRequired,
};
