import PropTypes from 'prop-types';
import { HStack, Box } from '@chakra-ui/react';
import AssetDetail from '../AssetDetail';

export default function AssetDetailRow({
  asset: {
    fiftyTwoWeekHigh,
    fiftyTwoWeekHighChangePercent,
    fiftyTwoWeekLow,
    fiftyTwoWeekLowChangePercent,
    regularMarketVolume,
  },
}) {
  return (
    <HStack w="100%" spacing="0.5rem" align="start" justify="start">
      <Box w="7.5rem">
        <AssetDetail
          title="Trading Volume"
          change={0}
          value={regularMarketVolume}
          isVolume
        />
      </Box>
      <Box w="7.5rem">
        <AssetDetail
          title="52 Week High"
          change={
            !Number.isNaN(fiftyTwoWeekHighChangePercent)
              ? fiftyTwoWeekHighChangePercent
              : 0
          }
          value={fiftyTwoWeekHigh}
        />
      </Box>
      <Box w="7.5rem">
        <AssetDetail
          title="52 week Low"
          change={
            !Number.isNaN(fiftyTwoWeekLowChangePercent)
              ? fiftyTwoWeekLowChangePercent
              : 0
          }
          value={fiftyTwoWeekLow}
        />
      </Box>
    </HStack>
  );
}

AssetDetailRow.propTypes = {
  asset: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    regularMarketPrice: PropTypes.number.isRequired,
    regularMarketChange: PropTypes.number.isRequired,
    regularMarketChangePercent: PropTypes.number.isRequired,
    marketState: PropTypes.string.isRequired,
    fiftyTwoWeekHigh: PropTypes.number.isRequired,
    fiftyTwoWeekLow: PropTypes.number.isRequired,
    marketCap: PropTypes.number.isRequired,
    regularMarketVolume: PropTypes.number.isRequired,
    fiftyTwoWeekHighChangePercent: PropTypes.number.isRequired,
    fiftyTwoWeekLowChangePercent: PropTypes.number.isRequired,
  }).isRequired,
};
