/* eslint-disable camelcase */
import useSWR from 'swr';
import moment from 'moment';

export const YAHOO_TRENDING_TICKERS_CANONICAL_NAME = 'TRENDING';

export const shortenLargeNumber = (marketCap) => {
  const marketCapString = marketCap?.toString();
  const marketCapDigitCount = marketCapString?.length;
  let abbreviatedString;
  let singleLetterNotation;
  if (marketCapDigitCount > 12) {
    const diff = marketCapDigitCount - 12;
    abbreviatedString = `${marketCapString?.substring(0, diff)}.${
      marketCapString[diff + 1]
    }`;
    singleLetterNotation = 't';
  } else if (marketCapDigitCount > 9) {
    const diff = marketCapDigitCount - 9;
    abbreviatedString = `${marketCapString?.substring(0, diff)}.${
      marketCapString[diff + 1]
    }`;
    singleLetterNotation = 'b';
  } else if (marketCapDigitCount > 6) {
    const diff = marketCapDigitCount - 6;
    abbreviatedString = `${marketCapString?.substring(0, diff)}.${
      marketCapString[diff + 1]
    }`;
    singleLetterNotation = 'm';
  } else if (marketCapDigitCount > 3) {
    const diff = marketCapDigitCount - 3;
    abbreviatedString = `${marketCapString?.substring(0, diff)}.${
      marketCapString[diff + 1]
    }`;
    singleLetterNotation = 'k';
  }
  const value = `${abbreviatedString}${singleLetterNotation}`;
  return value;
};

const getDateForPulp = (date) => {
  const parsedDate = moment(date);
  return parsedDate;
};

const fetchTweets = async (url, { asset }) => {
  const tweetResponse = await fetch(
    `https://sockpulp-server.azurewebsites.net/tweets/${asset.symbol}`
  );

  const responseJson = await tweetResponse.json();
  const { data } = responseJson;
  const { statuses: tweets } = data;

  const tweetList = tweets.reduce((list, tweet) => {
    const {
      text,
      entities,
      created_at,
      retweet_count,
      favorite_count,
      user: { screen_name },
      id,
    } = tweet;

    const link = entities?.urls[0]?.expanded_url;

    const createdDate = getDateForPulp(created_at);

    const tweetData = {
      text,
      date: createdDate,
      creator: screen_name,
      source: 'twitter',
      link,
      score: (retweet_count + favorite_count) / 1000,
      id: id.toString(),
    };

    list.push(tweetData);

    return list;
  }, []);
  return tweetList;
};

const fetchRedditComments = async (url, { asset }) => {
  const response = await fetch(
    `https://sockpulp-server.azurewebsites.net/reddit/${asset.symbol}`
  );
  const responseJson = await response.json();
  const { data } = responseJson;

  const redditCommentList = data.reduce((list, comment) => {
    const { body, created_utc, permalink, author, id } = comment;

    const link = `https://reddit.com${permalink}`;

    const date = getDateForPulp(created_utc * 1000);

    const commentData = {
      text: body,
      date,
      creator: author,
      source: 'reddit',
      link,
      score: 0.25,
      id: id.toString(),
    };

    list.push(commentData);

    return list;
  }, []);

  return redditCommentList;
};

export const usePulps = (selectedAsset, pulpSourceTypes) => {
  const { data: tweets, error: tweetsErroring } = useSWR(
    selectedAsset &&
      selectedAsset?.symbol &&
      pulpSourceTypes.includes('twitter')
      ? [`/api/search-tweets/${selectedAsset.symbol}`, selectedAsset]
      : null,
    (url, asset) => fetchTweets(url, { asset })
  );

  const { data: redditComments, error: redditCommentsErroring } = useSWR(
    selectedAsset && selectedAsset?.symbol && pulpSourceTypes.includes('reddit')
      ? [`/api/search-reddit-comments/${selectedAsset.symbol}`, selectedAsset]
      : null,
    (url, asset) => fetchRedditComments(url, { asset })
  );

  let pulps = [];
  if (tweets !== undefined) {
    pulps = [...pulps, ...tweets];
  }

  if (redditComments !== undefined) {
    pulps = [...pulps, ...redditComments];
  }

  const sortedPulps = pulps.sort((a, b) => b.score - a.score);

  return {
    pulps: sortedPulps,
    arePulpsLoading:
      !tweets && !tweetsErroring && !redditComments && !redditCommentsErroring,
    arePulpsErroring: tweetsErroring || redditCommentsErroring,
  };
};

const fetchNoiseIndex = async (url, { assetSymbol: symbol }) => {
  const response = await fetch(
    `https://sockpulp-server.azurewebsites.net/noiseindex/${symbol}`
  );
  const responseText = await response.text();

  const scoreText = responseText.replace(/[{}]/g, '');

  const data = parseFloat(scoreText);

  return data;
};

export const useNoiseIndex = (symbol) => {
  const { data: noiseScore, error } = useSWR(
    symbol ? [`/api/noiseindex/${symbol}`, symbol] : null,
    (url, assetSymbol) => fetchNoiseIndex(url, { assetSymbol })
  );

  const scaleFactor = Math.floor(noiseScore / 5) + 1;

  const scaledNoiseIndexPercentage = noiseScore / (5 * scaleFactor);

  return {
    noiseScore: scaledNoiseIndexPercentage,
    noiseScoreIsLoading: !noiseScore && !error,
    noiseScoreError: error,
  };
};

export const SET_SELECTED_ASSET = 'SET_SELECTED_ASSET';
export const UPDATE_PULP_SOURCE_TYPES = 'UPDATE_PULP_SOURCE_TYPES';
export const NEXT_TOUR_STOP = 'NEXT_TOUR_STOP';
export const PREVIOUS_TOUR_STOP = 'PREVIOUS_TOUR_STOP';
export const CHAT_METER_TOUR_STOP = 'CHAT_METER_TOUR_STOP';
export const ASSET_LIST_TOUR_STOP = 'ASSET_LIST_TOUR_STOP';
export const SELECTED_ASSET_TOUR_STOP = 'SELECTED_ASSET_TOUR_STOP';
export const PULP_LIST_TOUR_STOP = 'PULP_LIST_TOUR_STOP';
export const NO_TOUR_STOP = 'NO_TOUR_STOP';
export const WELCOME_TOUR_STOP = 'WELCOME_TOUR_STOP';
export const QUIT_TOUR = 'QUIT_TOUR';
export const START_TOUR = 'START_TOUR';

export const homePageReducer = (state, action) => {
  const { type } = action;

  switch (type) {
    case SET_SELECTED_ASSET:
      return { ...state, selectedAsset: action.value };
    case UPDATE_PULP_SOURCE_TYPES:
      return { ...state, pulpSourceTypes: action.value };
    case NEXT_TOUR_STOP:
      return { ...state, tourLocation: action.value };
    case PREVIOUS_TOUR_STOP:
      return { ...state, tourLocation: action.value };
    case QUIT_TOUR:
      return { ...state, tourLocation: 0 };
    case START_TOUR:
      return { ...state, tourLocation: 1 };
    default:
      return state;
  }
};

export const tourStops = [
  NO_TOUR_STOP,
  WELCOME_TOUR_STOP,
  CHAT_METER_TOUR_STOP,
  ASSET_LIST_TOUR_STOP,
  SELECTED_ASSET_TOUR_STOP,
  PULP_LIST_TOUR_STOP,
];

export const previousTourStop = (currentLocation) => {
  if (currentLocation > 0)
    return { type: PREVIOUS_TOUR_STOP, value: currentLocation - 1 };
  return { type: PREVIOUS_TOUR_STOP, value: currentLocation };
};

export const nextTourStop = (currentLocation) => {
  if (currentLocation < tourStops.length - 1)
    return { type: NEXT_TOUR_STOP, value: currentLocation + 1 };
  return { type: NEXT_TOUR_STOP, value: currentLocation };
};

export const quitTourAction = {
  type: QUIT_TOUR,
};

export const startTourAction = {
  type: START_TOUR,
};

export const initialHomePageState = {
  pulpSourceTypes: ['twitter', 'reddit'],
  tourLocation: 0,
};

const fetchAssetList = async (url) => {
  const response = await fetch(url, {
    method: 'GET',
  });

  const assets = await response.json();

  return assets[0];
};

export const useAssetList = () => {
  const { data, error } = useSWR(
    'https://sockpulp-server.azurewebsites.net/marketdata/list',
    fetchAssetList
  );

  return {
    assetList: data,
    assetListLoading: !data && !error,
    assetListError: error,
  };
};
