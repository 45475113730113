import { VStack } from '@chakra-ui/react';
import PulpSkeleton from '../Pulp/PulpSkeleton';

export default function PulpListSkeleton() {
  return (
    <VStack id="pulp-list-skeleton" spacing="5rem" w="100%" h="75%">
      <PulpSkeleton />
      <PulpSkeleton />
      <PulpSkeleton />
      <PulpSkeleton />
    </VStack>
  );
}
