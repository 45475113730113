import PropTypes from 'prop-types';
import AssetHeading from '../AssetHeading/AssetHeading';
import SelectedAssetPopover from './SelectedAssetPopover';
import { SELECTED_ASSET_TOUR_STOP, tourStops } from '../../Pages/hooks';

export default function SelectedAsset({
  asset,
  currentlyOpenPopover,
  moveToPreviousTourStop,
  moveToNextTourStop,
  quitTour,
}) {
  if (asset) {
    return (
      <SelectedAssetPopover
        isOpen={tourStops[currentlyOpenPopover] === SELECTED_ASSET_TOUR_STOP}
        currentlyOpenPopover={currentlyOpenPopover}
        moveToPreviousTourStop={moveToPreviousTourStop}
        moveToNextTourStop={moveToNextTourStop}
        quitTour={quitTour}
      >
        <AssetHeading
          asset={asset}
          iconSize="1.5rem"
          nameSize="3xl"
          symbolSize="3xl"
          selected
        />
      </SelectedAssetPopover>
    );
  }
}
SelectedAsset.propTypes = {
  asset: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    regularMarketPrice: PropTypes.number.isRequired,
    regularMarketChange: PropTypes.number.isRequired,
    regularMarketChangePercent: PropTypes.number.isRequired,
    marketState: PropTypes.string.isRequired,
    fiftyTwoWeekHigh: PropTypes.number.isRequired,
    fiftyTwoWeekLow: PropTypes.number.isRequired,
    marketCap: PropTypes.number.isRequired,
    averageDailyVolume10Day: PropTypes.number.isRequired,
    regularMarketVolume: PropTypes.number.isRequired,
    fiftyTwoWeekHighChangePercent: PropTypes.number.isRequired,
    fiftyTwoWeekLowChangePercent: PropTypes.number.isRequired,
  }).isRequired,
  currentlyOpenPopover: PropTypes.number.isRequired,
  moveToPreviousTourStop: PropTypes.func.isRequired,
  moveToNextTourStop: PropTypes.func.isRequired,
  quitTour: PropTypes.func.isRequired,
};
