import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import AssetHeading from '../AssetHeading/AssetHeading';

export default function AssetButton({ asset, setSelectedAsset }) {
  return (
    <Box
      data-testid="asset-tab"
      onClick={() => setSelectedAsset(asset)}
      py="0.25rem"
      px="0.5rem"
      as="button"
      css={{
        ':active': {
          transform: 'scale(0.975)',
          webkitBoxShadow: '0px 0px 2px 1px rgba(0,0,0,0.15)',
          boxShadow: '0px 0px 1px 0.5px rgba(0,0,0,0.0.15)',
        },
      }}
      _hover={{
        background: 'gray.50',
        transition: 'background 0.25s',
        transform: 'scale(1.02)',
      }}
    >
      <AssetHeading
        asset={asset}
        iconSize="0.75rem"
        nameSize="md"
        symbolSize="md"
      />
    </Box>
  );
}

AssetButton.propTypes = {
  asset: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    regularMarketPrice: PropTypes.number.isRequired,
    regularMarketChange: PropTypes.number.isRequired,
    regularMarketChangePercent: PropTypes.number.isRequired,
    marketState: PropTypes.string.isRequired,
  }).isRequired,
  setSelectedAsset: PropTypes.func.isRequired,
};
