import { useNavigate } from 'react-router-dom';
import { Heading, HStack, Text } from '@chakra-ui/react';

function StockpulpLogo() {
  const navigate = useNavigate();
  return (
    <Heading
      as="button"
      className="website-title-header"
      onClick={() => navigate('/')}
    >
      <HStack spacing="0">
        <Text fontSize="4xl" color="blue.600" fontWeight="500">
          Stock
        </Text>
        <Text fontSize="4xl" color="blue.300" fontWeight="400">
          pulp
        </Text>
      </HStack>
    </Heading>
  );
}

export default StockpulpLogo;
