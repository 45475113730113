import { Stack, Heading, Divider, VStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import AssetButton from '../AssetButton/AssetButton';

export default function AssetListComponent({
  assets,
  setSelectedAsset,
  selectedAsset,
}) {
  const tabs = assets?.map((asset) => (
    <AssetButton
      asset={asset}
      key={asset.symbol}
      setSelectedAsset={setSelectedAsset}
      selected={asset.symbol === selectedAsset?.symbol}
    />
  ));

  return (
    <Stack
      direction={['row', 'row', 'column', 'column', 'column', 'column']}
      spacing="1.5rem"
      boxShadow="xl"
      borderWidth="0.25px"
      p="0.1rem"
      borderColor="gray.200"
      borderRadius="3px"
      bgColor="white"
      overflowY="auto"
      zIndex="10"
      h={['8.5rem', '8.5rem', '100%', '100%', '100%', '100%', '100%']}
    >
      <VStack
        align="start"
        h={['100%', '100%', '70vh', '70vh', '70vh', '70vh']}
        w="100%"
      >
        <Heading
          px="0.25rem"
          paddingTop="0.75rem"
          size="sm"
          fontWeight="600"
          justify="start"
        >
          Assets
        </Heading>
        <Divider />
        <Stack
          direction={['row', 'row', 'column', 'column', 'column', 'column']}
          spacing="1rem"
          maxW="100%"
          overflowY="auto"
          overflowX="auto"
        >
          {tabs}
        </Stack>
      </VStack>
    </Stack>
  );
}

AssetListComponent.defaultProps = {
  selectedAsset: null,
};

AssetListComponent.propTypes = {
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string.isRequired,
      displayName: PropTypes.string,
      regularMarketPrice: PropTypes.number.isRequired,
      regularMarketChange: PropTypes.number.isRequired,
      regularMarketChangePercent: PropTypes.number.isRequired,
      marketState: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  setSelectedAsset: PropTypes.func.isRequired,
  selectedAsset: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
  }),
};
