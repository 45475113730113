import PropTypes from 'prop-types';
import Skeleton from './AssetListSkeleton';
import AssetListComponent from './AssetListComponent';
import AssetListPopover from './AssetListPopover';

export default function AssetList({
  assets,
  setSelectedAsset,
  selectedAsset,
  loading,
  moveToPreviousTourStop,
  moveToNextTourStop,
  currentlyOpenPopover,
  quitTour,
}) {
  if (loading) return <Skeleton />;
  return (
    <AssetListPopover
      currentlyOpenPopover={currentlyOpenPopover}
      moveToPreviousTourStop={moveToPreviousTourStop}
      moveToNextTourStop={moveToNextTourStop}
      quitTour={quitTour}
    >
      <AssetListComponent
        assets={assets}
        setSelectedAsset={setSelectedAsset}
        selectedAsset={selectedAsset}
      />
    </AssetListPopover>
  );
}

AssetList.defaultProps = {
  selectedAsset: null,
  assets: [],
};

AssetList.propTypes = {
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string.isRequired,
      displayName: PropTypes.string,
      regularMarketPrice: PropTypes.number.isRequired,
      regularMarketChange: PropTypes.number.isRequired,
      regularMarketChangePercent: PropTypes.number.isRequired,
      marketState: PropTypes.string.isRequired,
    }).isRequired
  ),
  setSelectedAsset: PropTypes.func.isRequired,
  selectedAsset: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
  }),
  currentlyOpenPopover: PropTypes.number.isRequired,
  moveToPreviousTourStop: PropTypes.func.isRequired,
  moveToNextTourStop: PropTypes.func.isRequired,
  quitTour: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
