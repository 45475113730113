import PropTypes from 'prop-types';
import PreMarketSessionIcon from './PreMarketSessionIcon';
import RegularMarketSessionIcon from './RegularMarketSessionIcon';
import PostMarketSessionIcon from './PostMarketSessionIcon';
import ClosedMarketSessionIcon from './ClosedMarketSessionIcon';

export default function TradingSessionIcon({ marketState }) {
  if (marketState === 'PRE') return <PreMarketSessionIcon />;
  if (marketState === 'REGULAR') return <RegularMarketSessionIcon />;
  if (marketState === 'POST') return <PostMarketSessionIcon />;
  if (marketState === 'POSTPOST') return <ClosedMarketSessionIcon />;

  return null;
}

TradingSessionIcon.propTypes = {
  marketState: PropTypes.string.isRequired,
};
