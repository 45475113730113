import PropTypes from 'prop-types';
import {
  Checkbox,
  CheckboxGroup,
  HStack,
  Stat,
  StatLabel,
  Icon,
  Tooltip,
} from '@chakra-ui/react';
import { FaReddit, FaTwitter } from 'react-icons/fa';

export default function PulpFilter({ pulpSourceTypes, setPulpSourceTypes }) {
  return (
    <HStack w="100%" spacing="0.5rem" zIndex="0">
      <CheckboxGroup
        colorScheme="blue"
        defaultValue={pulpSourceTypes}
        onChange={(value) => setPulpSourceTypes(value)}
      >
        <Checkbox value="twitter" colorScheme="twitter">
          <Stat>
            <HStack>
              <Tooltip
                label={
                  pulpSourceTypes.includes('twitter')
                    ? 'Hide Tweets'
                    : 'Show Tweets'
                }
              >
                <StatLabel>Twitter</StatLabel>
              </Tooltip>
              <Icon as={FaTwitter} />
            </HStack>
          </Stat>
        </Checkbox>
        <Checkbox value="reddit" colorScheme="orange">
          <Stat>
            <HStack>
              <Tooltip
                label={
                  pulpSourceTypes.includes('reddit')
                    ? 'Hide Reddit comments'
                    : 'Show Reddit comments'
                }
              >
                <StatLabel>Reddit</StatLabel>
              </Tooltip>
              <Icon as={FaReddit} />
            </HStack>
          </Stat>
        </Checkbox>
      </CheckboxGroup>
    </HStack>
  );
}

PulpFilter.propTypes = {
  pulpSourceTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  setPulpSourceTypes: PropTypes.func.isRequired,
};
