import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Tooltip,
} from '@chakra-ui/react';

const SimpleModal = ({
  isOpen,
  onClose,
  title,
  body,
  footer,
  footerTooltip,
}) => (
  <>
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <Tooltip label={footerTooltip}>{footer}</Tooltip>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>
);

SimpleModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  body: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  footer: PropTypes.string.isRequired,
  footerTooltip: PropTypes.string.isRequired,
};

export default SimpleModal;
