import PropTypes from 'prop-types';
import PageHeaderComponent from './PageHeaderComponent';

export default function PageHeader({
  currentlyOpenPopover,
  moveToNextTourStop,
  quitTour,
  startTour,
}) {
  return (
    <PageHeaderComponent
      currentlyOpenPopover={currentlyOpenPopover}
      moveToNextTourStop={moveToNextTourStop}
      quitTour={quitTour}
      startTour={startTour}
    />
  );
}

PageHeader.propTypes = {
  currentlyOpenPopover: PropTypes.number.isRequired,
  moveToNextTourStop: PropTypes.func.isRequired,
  quitTour: PropTypes.func.isRequired,
  startTour: PropTypes.func.isRequired,
};
