import { Tooltip } from '@chakra-ui/react';
import { NotAllowedIcon } from '@chakra-ui/icons';

export default function ClosedMarketSessionIcon() {
  return (
    <Tooltip label="Market closed">
      <NotAllowedIcon h="1rem" w="1rem" />
    </Tooltip>
  );
}
