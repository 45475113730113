import { useEffect, useReducer, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import {
  Heading,
  Stack,
  VStack,
  Text,
  HStack,
  useEditableControls,
  ButtonGroup,
  IconButton,
  Flex,
  Editable,
  EditablePreview,
  Input,
  EditableInput,
  Skeleton,
  useToast,
  WrapItem,
  Wrap,
  Checkbox,
  Tooltip,
} from '@chakra-ui/react';
import { CloseIcon, CheckIcon, EditIcon } from '@chakra-ui/icons';
import PageLayout from '../../Components/PageLayout/PageLayout';
import {
  homePageReducer,
  initialHomePageState,
  nextTourStop,
  quitTourAction,
  startTourAction,
} from '../hooks';

const snakeCaseObjectKeys = (camelCasedObject) => {
  const snakeCasedObject = {};
  const keys = Object.keys(camelCasedObject);

  keys.forEach((key) => {
    const snakeCasedKey = key
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase();
    snakeCasedObject[snakeCasedKey] = camelCasedObject[key];
  });
  return snakeCasedObject;
};

export default function UserProfileComponent() {
  const [state, dispatch] = useReducer(homePageReducer, initialHomePageState);

  const { tourLocation } = state;

  const moveToNextTourStop = (currentLocation) =>
    dispatch(nextTourStop(currentLocation));
  const quitTour = () => dispatch(quitTourAction);
  const startTour = () => dispatch(startTourAction);

  const { instance, accounts } = useMsal();

  const accessTokenRequest = {
    account: accounts[0],
  };

  const [userId, updateUserId] = useState();

  useEffect(() => {
    if (accessTokenRequest) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          const userIdClaim = accessTokenResponse.idTokenClaims.oid;
          updateUserId(userIdClaim);
        });
    }
  }, [accessTokenRequest]);

  const [userProfile, updateUserProfile] = useState(null);
  const [userEmailAddress, updateUserEmailAddress] = useState();
  const [userPhoneNumber, updateUserPhoneNumber] = useState('555-555-5555');
  const [assets, updateAssets] = useState([]);
  const [userFollowedAssets, updateUserFollowedAssets] = useState([]);
  const [userNotificationMethods, updateUserNotificationMethods] = useState([]);

  useEffect(() => {
    if (userId) {
      const fetchUserProfile = async (id) => {
        const response = await fetch(
          `https://bibber.azurewebsites.net/api/users/${id}`
        );

        const responseJson = await response.json();
        updateUserProfile(responseJson);
        updateUserEmailAddress(responseJson.emailAddress);
        updateUserPhoneNumber(responseJson?.phoneNumber);
        updateUserFollowedAssets(responseJson?.followedAssets || []);
        updateUserNotificationMethods(responseJson?.notificationMethods || []);
      };
      fetchUserProfile(userId);
    }
  }, [userId]);

  useEffect(() => {
    const fetchAssets = async () => {
      const response = await fetch(
        'https://bibber.azurewebsites.net/api/assets/?'
      );

      const responseJSON = await response.json();
      updateAssets(responseJSON);
    };

    fetchAssets();
  }, []);

  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
        <IconButton icon={<CloseIcon />} {...getCancelButtonProps()} />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <IconButton size="sm" icon={<EditIcon />} {...getEditButtonProps()} />
      </Flex>
    );
  }

  const toast = useToast();

  const postUserProfileUpdate = async (currentData, update, id) => {
    const updatedProfile = { ...currentData, ...update, id };
    const snakeCasedUpdatedProfile = snakeCaseObjectKeys(updatedProfile);
    const response = await fetch(
      `https://bibber.azurewebsites.net/api/users/`,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(snakeCasedUpdatedProfile),
      }
    );

    if (!response.ok) {
      if (update?.phoneNumber) {
        toast({
          title: 'Phone number is invalid.',
          description:
            'Please enter a valid US phone number in the form 555-555-5555',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
        });
      }
    } else {
      const responseJson = await response.json();
      updateUserProfile(responseJson);
      updateUserEmailAddress(responseJson?.emailAddress);
      updateUserPhoneNumber(responseJson?.phoneNumber);
      updateUserFollowedAssets(responseJson?.followedAssets || []);
      updateUserNotificationMethods(responseJson?.notificationMethods || []);
    }
  };

  return (
    <PageLayout
      currentlyOpenPopover={tourLocation}
      moveToNextTourStop={moveToNextTourStop}
      quitTour={quitTour}
      startTour={startTour}
    >
      <Stack
        direction={['column', 'column', 'row', 'row', 'row', 'row', 'row']}
        flexFlow={[
          'column-reverse',
          'column-reverse',
          'row',
          'row',
          'row',
          'row',
          'row',
        ]}
        id="entire-app-content"
        w="100%"
        align="start"
        justify="space-between"
        spacing={['1rem', '1rem', '3rem', '3rem', '3rem', '3rem', '3rem']}
      >
        <VStack
          id="main-content"
          paddingTop={['5rem', '5rem', 0, 0, 0, 0, 0]}
          w={['100%', '100%', '55%', '65%', '75%', '80%', '80%']}
          spacing="2rem"
          borderColor="gray.300"
          bgColor="white"
          align="left"
          color="gray.600"
        >
          <Heading size="lg">Your profile</Heading>
          <Heading size="md">Contact info</Heading>
          <HStack>
            <Text fontWeight="700">Email address:</Text>
            <>
              {userEmailAddress ? (
                <Text>{userEmailAddress}</Text>
              ) : (
                <Skeleton h="1.5rem" w="10rem" />
              )}
            </>
          </HStack>
          <Editable
            textAlign="center"
            fontSize="md"
            isPreviewFocusable={false}
            placeholder="555-555-5555"
            value={userPhoneNumber}
            onChange={(phoneNumber) => {
              updateUserPhoneNumber(phoneNumber);
            }}
            onSubmit={(phoneNumber) => {
              if (
                phoneNumber === '555-555-5555' ||
                phoneNumber === '' ||
                !phoneNumber
              ) {
                return;
              }
              const update = { phoneNumber };
              postUserProfileUpdate(userProfile, update, userId);
            }}
          >
            <HStack>
              <Text fontWeight="700">Phone number:</Text>
              {userProfile ? (
                <>
                  <EditablePreview />
                  <Input as={EditableInput} type="tel" />
                  <EditableControls />
                </>
              ) : (
                <Skeleton h="1.5rem" w="10rem" />
              )}
            </HStack>
          </Editable>
          <Heading size="md">Followed assets</Heading>
          <Wrap>
            {assets.map((asset) => (
              <Tooltip
                label={`${
                  userFollowedAssets.includes(asset.symbol)
                    ? 'Unfollow'
                    : 'Follow'
                } ${asset.name}`}
                key={asset.symbol}
              >
                <WrapItem w="10rem" h="2rem">
                  <Checkbox
                    value={asset.symbol}
                    isChecked={userFollowedAssets.includes(asset.symbol)}
                    onChange={(e) => {
                      let followedAssets;
                      if (e.target.checked) {
                        if (userFollowedAssets) {
                          if (userFollowedAssets.length >= 5) {
                            toast({
                              title: 'Max assets followed',
                              description:
                                'Sorry! You can only follow 5 assets right now.',
                              status: 'error',
                              duration: 9000,
                              isClosable: true,
                              position: 'top',
                            });
                            return;
                          }

                          followedAssets = [
                            ...userFollowedAssets,
                            e.target.value,
                          ];
                        } else {
                          followedAssets = [e.target.value];
                        }
                      } else {
                        followedAssets = [...userFollowedAssets].filter(
                          (symbol) => symbol !== e.target.value
                        );
                      }
                      const update = { followedAssets };
                      postUserProfileUpdate(userProfile, update, userId);
                    }}
                  >
                    {asset.symbol}
                  </Checkbox>
                </WrapItem>
              </Tooltip>
            ))}
          </Wrap>
          <Heading size="md">Chat meter notifications</Heading>
          <HStack>
            {['SMS'].map((notificationType) => (
              <Tooltip
                label={`${
                  userNotificationMethods.includes(
                    notificationType.toLowerCase()
                  )
                    ? 'Disable'
                    : 'Enable'
                } ${notificationType} notifications`}
                key={notificationType}
              >
                <Checkbox
                  key={notificationType}
                  w="10rem"
                  isChecked={userNotificationMethods.includes(
                    notificationType.toLowerCase()
                  )}
                  value={notificationType.toLowerCase()}
                  onChange={(e) => {
                    let notificationMethods;
                    if (e.target.checked) {
                      if (userNotificationMethods) {
                        notificationMethods = [
                          ...userNotificationMethods,
                          e.target.value,
                        ];
                      } else {
                        notificationMethods = [e.target.value];
                      }
                    } else {
                      notificationMethods = [...userNotificationMethods].filter(
                        (method) => method !== e.target.value
                      );
                    }
                    const update = { notificationMethods };
                    postUserProfileUpdate(userProfile, update, userId);
                  }}
                >
                  {notificationType}
                </Checkbox>
              </Tooltip>
            ))}
          </HStack>
        </VStack>
      </Stack>
    </PageLayout>
  );
}
