import PropTypes from 'prop-types';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';
import RequireAuth from './Components/RequireAuth';
import UserProfile from './Pages/UserProfile';

const breakpoints = ['0em', '30em', '48em', '62em', '80em', '96em', '108em'];

const theme = extendTheme({ breakpoints });

function App({ pca }) {
  return (
    <MsalProvider instance={pca}>
      <Router>
        <ChakraProvider theme={theme}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route
              path="/user-profile"
              element={
                <RequireAuth>
                  <UserProfile />
                </RequireAuth>
              }
            />
          </Routes>
        </ChakraProvider>
      </Router>
    </MsalProvider>
  );
}

App.propTypes = {
  pca: PropTypes.instanceOf(PublicClientApplication).isRequired,
};

export default App;
