import { Stack, Heading, Divider, VStack } from '@chakra-ui/react';
import AssetButtonSkeleton from '../AssetButton/AssetButtonSkeleton';

// current;y 16 tabs; for some reason using a for loop to generate
// these tabs used ton of RAM
export default function AssetButtonListSkeleton() {
  return (
    <Stack
      direction={['row', 'row', 'column', 'column', 'column', 'column']}
      spacing="1.5rem"
      boxShadow="xl"
      borderWidth="0.25px"
      p="0.1rem"
      borderColor="gray.200"
      borderRadius="3px"
      bgColor="white"
      overflowY="auto"
      position={[
        'none',
        'none',
        'relative',
        'relative',
        'relative',
        'relative',
        'relative',
      ]}
      h={['7.5rem', '7.5rem', '100%', '100%', '100%', '100%', '100%']}
    >
      <VStack
        align="start"
        h={['100%', '100%', '70vh', '70vh', '70vh', '70vh']}
        w="100%"
      >
        <Heading
          px="0.25rem"
          paddingTop="0.75rem"
          size="sm"
          fontWeight="600"
          justify="start"
        >
          Assets
        </Heading>
        <Divider />
        <Stack
          direction={['row', 'row', 'column', 'column', 'column', 'column']}
          spacing="1rem"
          maxW="100%"
          overflowY="auto"
          overflowX="auto"
        >
          <AssetButtonSkeleton />
          <AssetButtonSkeleton />
          <AssetButtonSkeleton />
          <AssetButtonSkeleton />
          <AssetButtonSkeleton />
        </Stack>
      </VStack>
    </Stack>
  );
}
