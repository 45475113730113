import PropTypes from 'prop-types';
import { Stack, Skeleton } from '@chakra-ui/react';

export default function AssetSnapshotFieldSkeleton({ direction }) {
  return (
    <Stack
      direction={direction}
      data-testid="selected-asset-snapshot-field"
      spacing="1rem"
      align="start"
      width="100%"
    >
      <Skeleton
        data-testid="selected-asset-snapshot-field-title"
        h="1.5rem"
        w="50%"
      />
      <Skeleton
        data-testid="selected-asset-snapshot-field-title"
        h="1rem"
        w="70%"
      />
      <Skeleton
        data-testid="selected-asset-snapshot-field-title"
        h="2rem"
        w="30%"
      />
    </Stack>
  );
}

AssetSnapshotFieldSkeleton.defaultProps = {
  direction: 'column',
};

AssetSnapshotFieldSkeleton.propTypes = {
  direction: PropTypes.string,
};
