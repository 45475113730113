import { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ButtonGroup,
  Button,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverCloseButton,
  PopoverTrigger,
} from '@chakra-ui/react';

export default function ChatMeterTourStop({
  isOpen,
  moveToPreviousTourStop,
  moveToNextTourStop,
  quitTour,
  children,
  currentlyOpenPopover,
}) {
  const { initialFocusRefChatMeter } = useRef;
  return (
    <Popover
      isOpen={isOpen}
      closeOnBlur={false}
      arrowSize={15}
      initialFocusRef={initialFocusRefChatMeter}
    >
      <PopoverTrigger>
        <Box w="100%">{children}</Box>
      </PopoverTrigger>
      <PopoverContent boxShadow="2xl">
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Buzzzzzzzzz 🐝
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton onClick={() => quitTour()} />
        <PopoverBody>
          Meet the chat meter, the heart of Stockpulp. We analyze chatter from
          social media websites to help you determine which assets are the most
          popular right now.
        </PopoverBody>
        <PopoverFooter
          border="0"
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={4}
        >
          <ButtonGroup size="sm">
            <Button
              onClick={() => moveToPreviousTourStop(currentlyOpenPopover)}
              colorScheme="blue"
              ref={initialFocusRefChatMeter}
            >
              Previous
            </Button>
            <Button
              onClick={() => moveToNextTourStop(currentlyOpenPopover)}
              colorScheme="blue"
              ref={initialFocusRefChatMeter}
            >
              Next
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

ChatMeterTourStop.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  moveToPreviousTourStop: PropTypes.func.isRequired,
  moveToNextTourStop: PropTypes.func.isRequired,
  quitTour: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  currentlyOpenPopover: PropTypes.number.isRequired,
};
