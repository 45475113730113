import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Navigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { useToast } from '@chakra-ui/react';

export default function RequireAuth({ children }) {
  const isAuthenticated = useIsAuthenticated();
  const toast = useToast();

  useEffect(() => {
    if (!isAuthenticated) {
      toast({
        title: 'You are not logged in.',
        description: 'Please sign in or sign up to personalize your settings.',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [isAuthenticated]);

  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};
